<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">
        {{ inputs.language === 'en' ? 'Experiment 6: Question 2' : 'Expérience 6: Question 2' }}
      </h2>

      <p class="mb-n3">
        {{
          inputs.language === 'en'
            ? 'What is the conjugate acid of phenylmagnesium bromide?'
            : "Quel est l'acide conjugué du bromure de phénylmagnésium?"
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab6Q2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'Bromobenzene',
          value: 'bromo',
        },
        {
          text: 'Hydrobromic acid',
          value: 'acid',
        },
        {
          text: 'Benzene',
          value: 'benzene',
        },
        {
          text: '$\\ce{H3O+}$',
          value: 'hydronium',
        },
        {
          text: '$\\ce{HMgBr}$',
          value: 'bromide',
        },
      ],
      optionsFr: [
        {
          text: 'Bromobenzène',
          value: 'bromo',
        },
        {
          text: 'Acide hydrobromique',
          value: 'acid',
        },
        {
          text: 'Benzène',
          value: 'benzene',
        },
        {
          text: '$\\ce{H3O+}$',
          value: 'hydronium',
        },
        {
          text: '$\\ce{HMgBr}$',
          value: 'bromide',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
